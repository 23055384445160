import { buttonVariants } from "./button.styles";
import { compose, cva, cx, type VariantProps } from "./style.utils";

// -----------------------------------------------------------------------------

const alertRootVariants = cva({
  base: [
    // layout
    "group/alert @container/alert",
    // foreground
    "text-sm text-shade-100",
  ],
  variants: {
    palette: {
      error: [
        // border
        "border-l-error-100",
        // background
        "bg-error-20",
      ],
      warning: [
        // border
        "border-l-warning-100",
        // background
        "bg-warning-20",
      ],
      success: [
        // border
        "border-l-success-100",
        // background
        "bg-success-20",
      ],
      info: [
        // border
        "border-l-info-100",
        // background
        "bg-info-20",
      ],
      neutral: [
        // border
        "border-l-neutral-100",
        // background
        "bg-neutral-20",
      ],
    },
  },
  defaultVariants: {
    palette: "neutral",
  },
});

// -----------------------------------------------------------------------------

export type AlertCalloutVariants = VariantProps<typeof alertCalloutVariants>;

export const alertCalloutVariants = compose(
  alertRootVariants,
  cva({
    base: [
      // layout
      "grid w-full grid-flow-row grid-cols-alert-callout px-15px py-16px",
      // positioning
      "relative",
      // radii/shadow
      "rounded-l rounded-r-md",
      // border
      "border-x-4 border-r-transparent",
    ],
  }),
);

// -----------------------------------------------------------------------------

export type AlertBannerVariants = VariantProps<typeof alertBannerVariants>;

export const alertBannerVariants = compose(
  alertRootVariants,
  cva({
    base: "grid grid-flow-row grid-cols-main py-11px md:grid-cols-main-md",
  }),
);

export const alertBannerContainerClassNames = cx(
  // layout span
  "col-start-content-s col-end-content-e",
  // layout
  "grid grid-flow-row grid-cols-alert-banner items-center",
  // positioning
  "relative",
);

// -----------------------------------------------------------------------------

export type AlertIconVariants = VariantProps<typeof alertIconVariants>;

export const alertIconVariants = cva({
  base: [
    // layout
    "row-start-1 col-start-icon-s col-end-icon-e",
    // layout offset callout
    "group-[[data-variant=callout]]/alert:group-[[data-intent=error]]/alert:translate-y-2px",
    "group-[[data-variant=callout]]/alert:group-[[data-intent=warning]]/alert:translate-y-1px",
    "group-[[data-variant=callout]]/alert:group-[[data-intent=success]]/alert:translate-y-1px",
    "group-[[data-variant=callout]]/alert:group-[[data-intent=info]]/alert:translate-y-2px",
    "group-[[data-variant=callout]]/alert:group-[[data-intent=help]]/alert:translate-y-2px",
    "group-[[data-variant=callout]]/alert:group-[[data-intent=loading]]/alert:mt-1px",
    // layout offset banner
    "group-[[data-variant=banner]]/alert:group-[[data-intent=error]]/alert:translate-y-1px",
    "group-[[data-variant=banner]]/alert:group-[[data-intent=info]]/alert:translate-y-1px",
    "group-[[data-variant=banner]]/alert:group-[[data-intent=help]]/alert:translate-y-1px",
    "group-[[data-variant=banner]]/alert:group-[[data-intent=loading]]/alert:mt-1px",
    // foreground
    "text-neutral-120",
    "group-[[data-intent=error]]/alert:text-error-100",
    "group-[[data-intent=warning]]/alert:text-warning-100",
    "group-[[data-intent=success]]/alert:text-success-120",
    "group-[[data-intent=info]]/alert:text-info-120",
    "group-[[data-intent=help]]/alert:text-neutral-120",
    "group-[[data-intent=loading]]/alert:text-link-hover",
    // animation
    "group-[[data-intent=loading]]/alert:animate-spin",
  ],
});

// -----------------------------------------------------------------------------

export type AlertContentVariants = VariantProps<typeof alertContentVariants>;

export const alertContentVariants = cva({
  base: [
    // layout
    "row-start-1 col-start-content-s col-end-content-e",
    // layout callout
    "group-[[data-variant=callout]]/alert:space-y-7px",
    // layout banner
    "group-[[data-variant=banner]]/alert:space-x-7px",
    "group-[[data-variant=banner]]/alert:*:inline",
  ],
});

// -----------------------------------------------------------------------------

export type AlertTitleVariants = VariantProps<typeof alertTitleVariants>;

export const alertTitleVariants = cva({
  base: "font-semibold leading-sm last:font-normal",
});

// -----------------------------------------------------------------------------

export type AlertDescriptionVariants = VariantProps<
  typeof alertDescriptionVariants
>;

export const alertDescriptionVariants = cva({
  base: "leading-sm",
});

// -----------------------------------------------------------------------------

export type AlertCancelButtonVariants = VariantProps<
  typeof alertCancelButtonVariants
>;

export const alertCancelButtonVariants = cva({
  base: [
    buttonVariants({ variant: "outline", size: "sm" }),
    // layout banner
    "group-[[data-variant=banner]]/alert:col-start-buttons-cancel-s",
    "group-[[data-variant=banner]]/alert:col-end-buttons-cancel-e",
    // layout callout
    "group-[[data-variant=callout]]/alert:mt-10px",
    "group-[[data-variant=callout]]/alert:col-start-content-s",
    "group-[[data-variant=callout]]/alert:col-end-content-e",
    // foreground
    "group-[[data-intent=error]]/alert:text-error-100",
    "group-[[data-intent=warning]]/alert:text-warning-120",
    "group-[[data-intent=success]]/alert:text-success-120",
    // focus/hover/active reset
    "hover:bg-black/5 focus:bg-transparent active:bg-black/5",
  ],
});

// -----------------------------------------------------------------------------

export type AlertActionButtonVariants = VariantProps<
  typeof alertActionButtonVariants
>;

export const alertActionButtonVariants = cva({
  base: [
    buttonVariants({ variant: "solid", size: "sm" }),
    // layout banner
    "group-[[data-variant=banner]]/alert:col-start-buttons-action-s",
    "group-[[data-variant=banner]]/alert:col-end-buttons-action-e",
    // layout callout
    "group-[[data-variant=callout]]/alert:mt-10px",
    "group-[[data-variant=callout]]/alert:col-start-content-s",
    "group-[[data-variant=callout]]/alert:col-end-content-e",
    // background
    "group-[[data-intent=error]]/alert:bg-error-100",
    "group-[[data-intent=warning]]/alert:bg-warning-100",
    "group-[[data-intent=success]]/alert:bg-success-100",
  ],
});

// -----------------------------------------------------------------------------

export type AlertCloseButtonVariants = VariantProps<
  typeof alertCloseButtonVariants
>;

export const alertCloseButtonVariants = cva({
  base: [
    buttonVariants({ variant: "ghost", size: "sm", iconOnly: true }),
    // layout
    "row-start-1 col-start-close-s col-end-close-e",
    "size-30px",
    // layout towards corner
    "group-[[data-variant=callout]]/alert:-mb-10px group-[[data-variant=callout]]/alert:-translate-y-5px group-[[data-variant=callout]]/alert:translate-x-8px",
    "group-[[data-variant=banner]]/alert:translate-x-8px",
    // foreground
    "text-neutral-120",
    "group-[[data-intent=error]]/alert:text-error-100",
    "group-[[data-intent=warning]]/alert:text-warning-120",
    "group-[[data-intent=success]]/alert:text-success-120",
    "group-[[data-intent=info]]/alert:text-info-120",
    // focus/hover/active reset
    "hover:bg-black/5 focus:bg-transparent active:bg-black/5",
  ],
});
