import { cva, type VariantProps } from "./style.utils";

export type TypographyVariants = VariantProps<typeof typographyVariants>;

export const typographyVariants = cva({
  variants: {
    palette: {
      unset: null,
      accent: "text-link-idle",
      error: "text-error-100",
      warning: "text-warning-100",
      success: "text-success-100",
      info: "text-info-100",
      neutral: "text-shade-100",
      "neutral-subtle": "text-shade-70",
      white: "text-white",
    },
    size: {
      unset: null,
      "2xs": "text-2xs",
      xs: "text-xs",
      sm: "text-sm",
      md: "text-md",
      h6: "text-h6",
      h5: "text-h5",
      h4: "text-h4",
      h3: "text-h3",
      h2: "text-h2",
      h1: "text-h1 tracking-tight",
    },
  },
  compoundVariants: [
    {
      size: ["h6", "h5", "h4", "h3", "h2", "h1"],
      className: "text-balance",
    },
    {
      size: ["h2", "h1"],
      className: "font-normal",
    },
    {
      size: ["h6", "h5", "h4", "h3"],
      className: "font-semibold",
    },
  ],
  defaultVariants: {
    palette: "unset",
    size: "md",
  },
});
