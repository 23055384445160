import {
  type NavRoutesConfig,
  type NavRoutesConfigItemSub,
} from "#app/_ui/components/headers/nav-routes-config";
import { type LinkProps } from "next/link";
import { type UrlObject } from "node:url";
import { type IconName } from "./icon.names";

// -----------------------------------------------------------------------------

export type NavRouteSub = {
  id: string;
  iconName?: IconName;
  element: React.ReactElement | string;
  path: LinkProps["href"];
};

export type NavRoute = {
  id: string;
  iconName: IconName;
  element: React.ReactElement | string;
} & (
  | {
      path: LinkProps["href"];
      paths?: never;
    }
  | {
      path?: never;
      paths: NavRouteSub[];
    }
);

export type NavRoutes = NavRoute[];

// -----------------------------------------------------------------------------

export const routeIconNameMap = {
  Home: "ms-home-filled",
  Investments: "ms-pie-chart-filled",
  ThreeSixtyWealthAndPlanning: "ms-track-changes-filled",
  InvestingTools: "ms-bar-chart-4-bars-filled",
  AccountServices: "ms-how-to-reg-filled",
  Documents: "ms-description-filled",
  BairdTrust: "custom-timeline",
  ProfileAndSettings: "ms-person-filled",
} as const satisfies Record<string, IconName>;

export function toRouteIconName(key: string) {
  const iconName: IconName =
    routeIconNameMap[key as keyof typeof routeIconNameMap] ??
    "custom-not-applicable";
  return iconName;
}

export function parseRoutesConfig(routesConfig: NavRoutesConfig): NavRoutes {
  return Object.entries(routesConfig).map(([id, route]) => {
    const iconName = route.iconName ?? "custom-not-applicable";
    const element = route.element;
    if ("paths" in route) {
      const paths = parseRoutesConfigSub(route.paths!);
      return { id, iconName, element, paths };
    } else {
      const path = route.path;
      return { id, iconName, element, path };
    }
  });
}

export function parseRoutesConfigSub(
  routesConfigSub: Record<string, NavRoutesConfigItemSub>,
) {
  return Object.entries(routesConfigSub).map(([id, route]) => {
    const element = route.element;
    const path = route.path;
    return { id, element, path };
  });
}

export function pathIsActive(value: UrlObject | string, pathname: string) {
  const str = typeof value === "object" ? value.pathname ?? "" : value;
  return str.split("?").at(0) === pathname;
}

export function findActiveRouteId(
  routes: NavRoutes,
  activePath: string,
): string | undefined {
  return routes.find(
    (route) =>
      (route.path && pathIsActive(route.path, activePath)) ||
      (route.paths &&
        route.paths.find((routeChild) =>
          pathIsActive(routeChild.path, activePath),
        )),
  )?.id;
}

// -----------------------------------------------------------------------------

export const mockRoutes = [
  {
    id: "link-0",
    iconName: "ms-help",
    element: "Link 0",
    path: "/link-0",
  },
  {
    id: "trigger-0",
    iconName: "ms-help",
    element: "Trigger 0",
    paths: [
      {
        id: "trigger-0-link-0",
        path: "/trigger-0/link-0",
        element: "Link 0.0",
      },
      {
        id: "trigger-0-link-1",
        path: "/trigger-0/link-1",
        element: "Link 0.1",
      },
      {
        id: "trigger-0-link-2",
        path: "/trigger-0/link-2",
        element: "Link 0.2",
      },
    ],
  },
  {
    id: "link-1",
    iconName: "ms-help",
    element: "Link 1",
    path: "/link-1",
  },
  {
    id: "trigger-1",
    iconName: "ms-help",
    element: "Trigger 1",
    paths: [
      {
        id: "trigger-1-link-0",
        element: "Link 1.0",
        path: "/trigger-1/link-0",
      },
      {
        id: "trigger-1-link-1",
        element: "Link 1.1",
        path: "/trigger-1/link-1",
      },
      {
        id: "trigger-1-link-2",
        element: "Link 1.2",
        path: "/trigger-1/link-2",
      },
    ],
  },
  {
    id: "link-2",
    iconName: "ms-help",
    element: "Link 2",
    path: "/link-2",
  },
  {
    id: "trigger-2",
    iconName: "ms-help",
    element: "Trigger 2",
    paths: [
      {
        id: "trigger-2-link-0",
        element: "Link 2.0",
        path: "/trigger-2/link-0",
      },
      {
        id: "trigger-2-link-1",
        element: "Link 2.1",
        path: "/trigger-2/link-1",
      },
      {
        id: "trigger-2-link-2",
        element: "Link 2.2",
        path: "/trigger-2/link-2",
      },
    ],
  },
] as const satisfies NavRoutes;

export const mockRoutesApp = [
  {
    id: "Home",
    iconName: "ms-home-filled",
    element: "Home",
    path: "/",
  },
  {
    id: "Investments",
    iconName: "ms-pie-chart-filled",
    element: "Investments",
    // No landing page, use content from "/investments/accounts"
    paths: [
      {
        id: "InvestmentsAccounts",
        element: "Accounts",
        path: "/investments/accounts",
      },
      {
        id: "InvestmentsHoldings",
        element: "Holdings",
        path: "/investments/holdings",
      },
      {
        id: "InvestmentsActivity",
        element: "Activity",
        path: "/investments/activity",
      },
      {
        id: "InvestmentsPerformance",
        element: "Performance",
        path: "/investments/performance",
      },
    ],
  },
  {
    id: "ThreeSixtyWealthAndPlanning",
    iconName: "ms-track-changes-filled",
    element: "360 Wealth & Planning",
    paths: [
      {
        id: "ThreeSixtyWealthOverview",
        element: "360 Overview",
        path: "/360-wealth",
      },
      {
        id: "PlanningOverview",
        element: "Planning",
        path: "/planning",
      },
      {
        id: "PlanningMyPlan",
        element: "My Plan",
        path: "/planning/my-plan",
      },
      {
        id: "ThreeSixtyWealthAccounts",
        element: "Accounts",
        path: "/360-wealth/accounts",
      },
      {
        id: "ThreeSixtyWealthTransactions",
        element: "Transactions",
        path: "/360-wealth/transactions",
      },
      {
        id: "ThreeSixtyWealthSpending",
        element: "Spending",
        path: "/360-wealth/spending",
      },
      {
        id: "ThreeSixtyWealthInvestments",
        element: "Investments",
        path: "/360-wealth/investments",
      },
      {
        id: "ThreeSixtyWealthSettings",
        element: "360 Wealth Settings",
        path: "/360-wealth/settings",
      },
      {
        id: "ThreeSixtyWealthFaqs",
        element: "360 Wealth FAQs",
        path: "/360-wealth/faqs",
      },
    ],
  },
  {
    id: "InvestingTools",
    iconName: "ms-bar-chart-4-bars-filled",
    element: "Investing Tools",
    // No landing page, use content from "/investing-tools/us-markets"
    paths: [
      {
        id: "InvestingToolsUsMarkets",
        element: "US Markets",
        path: "/investing-tools/us-markets",
      },
      {
        id: "InvestingToolsBairdResearch",
        element: "Baird Research",
        path: "/investing-tools/baird-research",
      },
      {
        id: "InvestingToolsMarketBriefing",
        element: "Market Briefing",
        path: "/investing-tools/market-briefing",
      },
      {
        id: "InvestingToolsPortfolioTracker",
        element: "Portfolio Tracker",
        path: "/investing-tools/portfolio-tracker",
      },
      {
        id: "InvestingToolsWatchlist",
        element: "Watchlist",
        path: "/investing-tools/watchlist",
      },
      {
        id: "InvestingToolsStocks",
        element: "Stocks",
        path: "/investing-tools/stocks",
      },
      {
        id: "InvestingToolsMutualFunds",
        element: "Mutual Funds",
        path: "/investing-tools/mutual-funds",
      },
      {
        id: "InvestingToolsNews",
        element: "News",
        path: "/investing-tools/news",
      },
    ],
  },
  {
    id: "AccountServices",
    iconName: "ms-how-to-reg-filled",
    element: "Account Services",
    // No landing page, use content from "/account-services/ebill"
    paths: [
      {
        id: "AccountServicesEbill",
        element: "eBill",
        path: "/account-services/ebill",
      },
      {
        id: "AccountServicesTransfers",
        element: "Transfers",
        path: "/account-services/transfers",
      },
      {
        id: "AccountServicesAwards",
        element: "Awards",
        path: "/account-services/awards",
      },
    ],
  },
  {
    id: "Documents",
    iconName: "ms-description-filled",
    element: "Documents",
    paths: [
      {
        id: "DocumentsOverview",
        element: "All Documents",
        path: "/documents",
      },
      {
        id: "DocumentsReceived",
        element: "Document Exchange",
        path: "/documents/received",
      },
      {
        id: "DocumentsStatements",
        element: "Statements",
        path: "/documents/statements",
      },
      {
        id: "DocumentsTaxForms",
        element: "Tax Forms",
        path: "/documents/tax-forms",
      },
      {
        id: "DocumentsConfirmations",
        element: "Confirmations",
        path: "/documents/confirmations",
      },
      {
        id: "DocumentsClientCommunications",
        element: "Client Communications",
        path: "/documents/client-communications",
      },
      {
        id: "DocumentsChecks",
        element: "Checks",
        path: "/documents/checks",
      },
      {
        id: "DocumentsFinancialPlans",
        element: "Financial Plans",
        path: "/documents/financial-plans",
      },
    ],
  },
  {
    id: "BairdTrust",
    iconName: "custom-timeline",
    element: "Baird Trust",
    paths: [
      {
        id: "BairdTrustOverview",
        element: "Dashboard",
        path: "/baird-trust",
      },
      {
        id: "BairdTrustGainsAndLosses",
        element: "Gains & Losses",
        path: "/baird-trust/gains-and-losses",
      },
      {
        id: "BairdTrustPortfolioDiversification",
        element: "Portfolio Diversification",
        path: "/baird-trust/portfolio-diversification",
      },
      {
        id: "BairdTrustAccounts",
        element: "Accounts",
        path: "/baird-trust/accounts",
      },
      {
        id: "BairdTrustHoldings",
        element: "Holdings",
        path: "/baird-trust/holdings",
      },
    ],
  },
  {
    id: "ProfileAndSettings",
    iconName: "ms-person-filled",
    element: "Profile & Settings",
    paths: [
      {
        id: "ProfileAndSettingsAccountAccess",
        element: "Account Access",
        path: "/profile-and-settings/account-access",
      },
      {
        id: "ProfileAndSettingsAccountGroups",
        element: "Account Groups",
        path: "/profile-and-settings/account-groups",
      },
      {
        id: "ProfileAndSettingsAccountNicknames",
        element: "Account Nicknames",
        path: "/profile-and-settings/account-nicknames",
      },
      {
        id: "ProfileAndSettingsPersonalInformation",
        element: "Personal Information",
        path: "/profile-and-settings/personal-information",
      },
      {
        id: "ProfileAndSettingsEmailVerification",
        element: "Email Verification",
        path: "/profile-and-settings/personal-information/verify-email",
      },
      {
        id: "ProfileAndSettingsUserIdAndPassword",
        element: "User ID & Password",
        path: "/profile-and-settings/user-id-and-password",
      },
      {
        id: "ProfileAndSettingsMailingAddresses",
        element: "Mailing Addresses",
        path: "/profile-and-settings/mailing-addresses",
      },
      {
        id: "ProfileAndSettingsPaperlessSettings",
        element: "Paperless Settings",
        path: "/profile-and-settings/paperless-settings",
      },
    ],
  },
] as const satisfies NavRoutes;
