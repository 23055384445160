import { forwardRef } from "react";
import {
  alertActionButtonVariants,
  alertBannerContainerClassNames,
  alertBannerVariants,
  alertCalloutVariants,
  alertCancelButtonVariants,
  alertCloseButtonVariants,
  alertContentVariants,
  alertDescriptionVariants,
  alertIconVariants,
  alertTitleVariants,
  type AlertActionButtonVariants,
  type AlertBannerVariants,
  type AlertCalloutVariants,
  type AlertCancelButtonVariants,
  type AlertCloseButtonVariants,
  type AlertContentVariants,
  type AlertDescriptionVariants,
  type AlertIconVariants,
  type AlertTitleVariants,
} from "./alert.styles";
import {
  toAlertAriaAtomic,
  toAlertAriaLive,
  toAlertIconName,
  toAlertPalette,
  type AlertIntent,
  type AlertRole,
} from "./alert.utils";
import { Icon, type IconProps, type IconRef } from "./icon";

// -----------------------------------------------------------------------------

export type AlertIconRef = IconRef;

export interface AlertIconProps
  extends Pick<IconProps, "className" | "name">,
    AlertIconVariants {}

const AlertIcon = forwardRef<AlertIconRef, AlertIconProps>(
  ({ className, name, ...props }, ref) => {
    return (
      <Icon
        {...props}
        name={name}
        className={alertIconVariants({ className })}
        ref={ref}
      />
    );
  },
);

AlertIcon.displayName = "AlertIcon";

export { AlertIcon };

// -----------------------------------------------------------------------------

export type AlertCalloutRef = React.ElementRef<"div">;

export interface AlertCalloutProps
  extends React.ComponentPropsWithoutRef<"div">,
    Omit<AlertCalloutVariants, "palette"> {
  intent?: AlertIntent;
  role?: AlertRole;
}

const AlertCallout = forwardRef<AlertCalloutRef, AlertCalloutProps>(
  ({ children, className, intent = "help", role = "none", ...props }, ref) => {
    return (
      <div
        {...props}
        role={role}
        aria-live={toAlertAriaLive(role)}
        aria-atomic={toAlertAriaAtomic(role)}
        data-intent={intent}
        data-variant="callout"
        className={alertCalloutVariants({
          className,
          palette: toAlertPalette(intent),
        })}
        ref={ref}
      >
        <AlertIcon name={toAlertIconName(intent)} />
        {children}
      </div>
    );
  },
);

AlertCallout.displayName = "AlertCallout";

export { AlertCallout as Alert, AlertCallout };

// -----------------------------------------------------------------------------

export type AlertBannerRef = React.ElementRef<"div">;

export interface AlertBannerProps
  extends React.ComponentPropsWithoutRef<"div">,
    Omit<AlertBannerVariants, "palette"> {
  intent?: AlertIntent;
  role?: AlertRole;
}

const AlertBanner = forwardRef<AlertBannerRef, AlertBannerProps>(
  ({ children, className, intent = "help", role = "none", ...props }, ref) => {
    return (
      <div
        {...props}
        role={role}
        aria-live={toAlertAriaLive(role)}
        aria-atomic={toAlertAriaAtomic(role)}
        data-intent={intent}
        data-variant="banner"
        className={alertBannerVariants({
          className,
          palette: toAlertPalette(intent),
        })}
        ref={ref}
      >
        <div className={alertBannerContainerClassNames}>
          <AlertIcon name={toAlertIconName(intent)} />
          {children}
        </div>
      </div>
    );
  },
);

AlertBanner.displayName = "AlertBanner";

export { AlertBanner };

// -----------------------------------------------------------------------------

export type AlertContentRef = React.ElementRef<"div">;

export interface AlertContentProps
  extends React.ComponentPropsWithoutRef<"div">,
    AlertContentVariants {}

const AlertContent = forwardRef<AlertContentRef, AlertContentProps>(
  ({ className, ...props }, ref) => {
    return (
      <div
        {...props}
        className={alertContentVariants({ className })}
        ref={ref}
      />
    );
  },
);

AlertContent.displayName = "AlertContent";

export { AlertContent };

// -----------------------------------------------------------------------------

export type AlertTitleRef = React.ElementRef<"div">;

export interface AlertTitleProps
  extends React.ComponentPropsWithoutRef<"div">,
    AlertTitleVariants {}

const AlertTitle = forwardRef<AlertTitleRef, AlertTitleProps>(
  ({ className, ...props }, ref) => {
    return (
      <div {...props} className={alertTitleVariants({ className })} ref={ref} />
    );
  },
);

AlertTitle.displayName = "AlertTitle";

export { AlertTitle };

// -----------------------------------------------------------------------------

export type AlertDescriptionRef = React.ElementRef<"div">;

export interface AlertDescriptionProps
  extends React.ComponentPropsWithoutRef<"div">,
    AlertDescriptionVariants {}

const AlertDescription = forwardRef<AlertDescriptionRef, AlertDescriptionProps>(
  ({ className, ...props }, ref) => {
    return (
      <div
        {...props}
        className={alertDescriptionVariants({ className })}
        ref={ref}
      />
    );
  },
);

AlertDescription.displayName = "AlertDescription";

export { AlertDescription };

// -----------------------------------------------------------------------------

export type AlertCancelButtonRef = React.ElementRef<"button">;

export interface AlertCancelButtonProps
  extends React.ComponentPropsWithoutRef<"button">,
    AlertCancelButtonVariants {
  asChild?: boolean;
}

const AlertCancelButton = forwardRef<
  AlertCancelButtonRef,
  AlertCancelButtonProps
>(({ className, ...props }, ref) => {
  return (
    <button
      type="button"
      {...props}
      className={alertCancelButtonVariants({ className })}
      ref={ref}
    />
  );
});

AlertCancelButton.displayName = "AlertCancelButton";

export { AlertCancelButton };

// -----------------------------------------------------------------------------

export type AlertActionButtonRef = React.ElementRef<"button">;

export interface AlertActionButtonProps
  extends React.ComponentPropsWithoutRef<"button">,
    AlertActionButtonVariants {}

const AlertActionButton = forwardRef<
  AlertActionButtonRef,
  AlertActionButtonProps
>(({ className, ...props }, ref) => {
  return (
    <button
      type="button"
      {...props}
      className={alertActionButtonVariants({ className })}
      ref={ref}
    />
  );
});

AlertActionButton.displayName = "AlertActionButton";

export { AlertActionButton };

// -----------------------------------------------------------------------------

export type AlertCloseButtonRef = React.ElementRef<"button">;

export interface AlertCloseButtonProps
  extends Omit<React.ComponentPropsWithoutRef<"button">, "children" | "type">,
    AlertCloseButtonVariants {}

const AlertCloseButton = forwardRef<AlertCloseButtonRef, AlertCloseButtonProps>(
  ({ className, ...props }, ref) => {
    return (
      <button
        type="button"
        {...props}
        className={alertCloseButtonVariants({ className })}
        ref={ref}
      >
        <Icon name="baird-close" />
        <span className="sr-only">Close alert</span>
      </button>
    );
  },
);

AlertCloseButton.displayName = "AlertCloseButton";

export { AlertCloseButton };
