import { forwardRef } from "react";
import {
  typographyVariants,
  type TypographyVariants,
} from "./typography.styles";

// -----------------------------------------------------------------------------

export type H1Ref = React.ElementRef<"h1">;

export interface H1Props
  extends React.ComponentPropsWithoutRef<"h1">,
    TypographyVariants {}

const H1 = forwardRef<H1Ref, H1Props>(
  ({ className, palette, size = "h1", ...props }, ref) => {
    return (
      <h1
        {...props}
        className={typographyVariants({ className, palette, size })}
        ref={ref}
      />
    );
  },
);

H1.displayName = "H1";

export { H1 };

// -----------------------------------------------------------------------------

export type H2Ref = React.ElementRef<"h2">;

export interface H2Props
  extends React.ComponentPropsWithoutRef<"h2">,
    TypographyVariants {}

const H2 = forwardRef<H2Ref, H2Props>(
  ({ className, palette, size = "h2", ...props }, ref) => {
    return (
      <h2
        {...props}
        className={typographyVariants({ className, palette, size })}
        ref={ref}
      />
    );
  },
);

H2.displayName = "H2";

export { H2 };

// -----------------------------------------------------------------------------

export type H3Ref = React.ElementRef<"h3">;

export interface H3Props
  extends React.ComponentPropsWithoutRef<"h3">,
    TypographyVariants {}

const H3 = forwardRef<H3Ref, H3Props>(
  ({ className, palette, size = "h3", ...props }, ref) => {
    return (
      <h3
        {...props}
        className={typographyVariants({ className, palette, size })}
        ref={ref}
      />
    );
  },
);

H3.displayName = "H3";

export { H3 };

// -----------------------------------------------------------------------------

export type H4Ref = React.ElementRef<"h4">;

export interface H4Props
  extends React.ComponentPropsWithoutRef<"h4">,
    TypographyVariants {}

const H4 = forwardRef<H4Ref, H4Props>(
  ({ className, palette, size = "h4", ...props }, ref) => {
    return (
      <h4
        {...props}
        className={typographyVariants({ className, palette, size })}
        ref={ref}
      />
    );
  },
);

H4.displayName = "H4";

export { H4 };

// -----------------------------------------------------------------------------

export type H5Ref = React.ElementRef<"h5">;

export interface H5Props
  extends React.ComponentPropsWithoutRef<"h5">,
    TypographyVariants {}

const H5 = forwardRef<H5Ref, H5Props>(
  ({ className, palette, size = "h5", ...props }, ref) => {
    return (
      <h5
        {...props}
        className={typographyVariants({ className, palette, size })}
        ref={ref}
      />
    );
  },
);

H5.displayName = "H5";

export { H5 };

// -----------------------------------------------------------------------------

export type H6Ref = React.ElementRef<"h6">;

export interface H6Props
  extends React.ComponentPropsWithoutRef<"h6">,
    TypographyVariants {}

const H6 = forwardRef<H6Ref, H6Props>(
  ({ className, palette, size = "h6", ...props }, ref) => {
    return (
      <h6
        {...props}
        className={typographyVariants({ className, palette, size })}
        ref={ref}
      />
    );
  },
);

H6.displayName = "H6";

export { H6 };

// -----------------------------------------------------------------------------

export type PRef = React.ElementRef<"p">;

export interface PProps
  extends React.ComponentPropsWithoutRef<"p">,
    TypographyVariants {}

const P = forwardRef<PRef, PProps>(
  ({ className, palette, size = "md", ...props }, ref) => {
    return (
      <p
        {...props}
        className={typographyVariants({ className, palette, size })}
        ref={ref}
      />
    );
  },
);

P.displayName = "P";

export { P };

// -----------------------------------------------------------------------------

export type SpanRef = React.ElementRef<"span">;

export interface SpanProps
  extends React.ComponentPropsWithoutRef<"span">,
    TypographyVariants {}

const Span = forwardRef<SpanRef, SpanProps>(
  ({ className, palette, size = "md", ...props }, ref) => {
    return (
      <span
        {...props}
        className={typographyVariants({ className, palette, size })}
        ref={ref}
      />
    );
  },
);

Span.displayName = "Span";

export { Span };
