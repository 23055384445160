import { cva, type VariantProps } from "./style.utils";

export type CardRootVariants = VariantProps<typeof cardRootVariants>;

export const cardRootVariants = cva({
  base: [
    "flex flex-col rounded-lg md:rounded-xl",
    "group/card @container/card",
  ],
  variants: {
    variant: {
      "white-shadow": "shadow",
      "white-shadow-md": "shadow-md",
      "white-border": "",
      "white-border-and-blur": "bg-white/80 backdrop-blur-xl",
      "blue-gradient":
        "from-19% to-86% bg-gradient-to-bl from-card-gradient-start to-card-gradient-end text-white",
    },
  },
  compoundVariants: [
    {
      variant: ["white-shadow", "white-shadow-md", "white-border"],
      className: "bg-white",
    },
    {
      variant: ["white-border", "white-border-and-blur"],
      className: "border border-shade-15",
    },
  ],
  defaultVariants: {
    variant: "white-shadow-md",
  },
});

// -----------------------------------------------------------------------------

export type CardHeaderVariants = VariantProps<typeof cardHeaderVariants>;

export const cardHeaderVariants = cva({
  base: [
    // layout
    "px-30px pt-30px",
    // radii/shadow
    "rounded-t-lg md:rounded-t-xl",
  ],
  variants: {
    palette: {
      unset: null,
      white: "",
      gray: [
        // background
        "bg-shade-2",
        // override
        "group-[[data-variant=blue-gradient]]/card:bg-white/5",
        "group-[[data-variant=blue-gradient]]/card:text-white",
      ],
    },
  },
  compoundVariants: [
    {
      palette: ["white", "gray"],
      className: [
        // layout
        "pb-30px",
        // border
        "border-b border-shade-15",
        // override
        "group-[[data-variant=blue-gradient]]/card:border-white/10",
      ],
    },
  ],
  defaultVariants: {
    palette: "unset",
  },
});

// -----------------------------------------------------------------------------

export type CardContentVariants = VariantProps<typeof cardContentVariants>;

export const cardContentVariants = cva({ base: "grow p-30px" });

// -----------------------------------------------------------------------------

export type CardFooterVariants = VariantProps<typeof cardFooterVariants>;

export const cardFooterVariants = cva({
  base: [
    // layout
    "flex flex-col gap-10px px-30px pb-30px",
    "@lg/card:flex-row @lg/card:items-center @lg/card:justify-end",
    // radii/shadow
    "rounded-b-lg md:rounded-b-xl",
  ],
  variants: {
    palette: {
      unset: null,
      white: "",
      gray: [
        // background
        "bg-shade-2",
        // override
        "group-[[data-variant=blue-gradient]]/card:bg-white/5",
        "group-[[data-variant=blue-gradient]]/card:text-white",
      ],
    },
  },
  compoundVariants: [
    {
      palette: ["white", "gray"],
      className: [
        // layout
        "py-15px",
        // border
        "border-t border-shade-15",
        // override
        "group-[[data-variant=blue-gradient]]/card:border-white/10",
      ],
    },
  ],
  defaultVariants: {
    palette: "unset",
  },
});
