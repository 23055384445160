"use client";

import * as userProfileService from "#app/_api/user-profile-service";
import { Card, CardContent, CardFooter } from "#ui/card";
import { Icon } from "#ui/icon";
import { Link, LinkButton } from "#ui/link";
import { Logo } from "#ui/logo";
import { Spinner } from "#ui/spinner";
import { H6, P } from "#ui/typography";
import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { IconHeader } from "../../cards/IconCardHeaders";
import ErrorBanner from "../../errors/error-banner";
import { useNavRoutes } from "../../headers/use-nav-routes";

//TODO This is duped
function formatAddress(addressLine1:string|null, addressLine2:string|null, city:string|null, state:string|null, zip:string|null):string {
    const formattedLines1And2 = [addressLine1, addressLine2].filter(Boolean).join(" ")
    return [formattedLines1And2, city, state, zip].filter(Boolean).join(", ")
}

export function MyFaTeam() {

    const routes = useNavRoutes();

    const {isSuccess, data: team, isFetched} = useQuery(userProfileService.useFaInfoQueryOptions());

    const branchAddress = useMemo(() => {
        return team ? formatAddress(team!.branchAddress1, team!.branchAddress2, team!.branchCity, team!.branchState, team!.branchPostalCode) : null;
    }, [team]);

    return (     
        <Card>
            <IconHeader icon="baird-wealth-strategy-webinar" title="My Team" />
            <CardContent >
                {isFetched == false && <div><Spinner className="w-full"/></div>}
                {isFetched && isSuccess && 
                    <div className="grid grid-cols-5 gap-4">
                        <div className="col-span-1">
                            <Logo size="xs" layout="mark-only"></Logo>
                        </div>
                        <div className="col-span-4">
                            <H6>{team?.branchDisplayName}</H6>
                            <P>{team?.branchPhone}</P>
                            {team?.branchUrl ? <Link target="_blank" href={team?.branchUrl}>{team!.branchUrl}</Link> : null }
                            <P>{branchAddress}</P>
                            <LinkButton href={routes.DocumentsReceived.path}  variant="outline" palette="neutral" ><Icon name="ms-upload-filled" />Upload document</LinkButton>
                        </div>
                    </div>
                }
                {isFetched && !isSuccess && <ErrorBanner error="An error occurred."/>

                }
            </CardContent>
            <CardFooter palette="white">
                <LinkButton className="w-full" href={routes.MyTeam.path!}>See all team members</LinkButton>
            </CardFooter>
        </Card>
    );
}