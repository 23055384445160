import { Alert, AlertContent, AlertTitle } from "#ui/alert";

export interface Props {
  error?: string;
  className?: string;
}

function ErrorBanner({ error, className }: Props) {
  if (!error) {
    return null;
  }
  return (
    <Alert intent="error" role="status" className={className} >
      <AlertContent>
        <AlertTitle>{error}</AlertTitle>
      </AlertContent>
    </Alert>
  );
}

export default ErrorBanner;
