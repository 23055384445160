

import { CardHeader } from "#ui/card";
import { Icon } from "#ui/icon";
import { IconName } from "#ui/icon.names";
import { H2, Span } from "#ui/typography";

export interface IconHeaderProps {
    icon: IconName,
    title: string
}
export function IconHeader({icon, title} : IconHeaderProps){
    return <CardHeader palette="gray">
        <H2 size="h6" className="space-x-8px">
            <Span><Icon name={icon} size="baird-md" inline /></Span>
            <Span>{title}</Span>
        </H2>
    </CardHeader>;
}